body {
  font-family: 'Titillium Web', sans-serif !important;


  font-display: swap !important;
}

a,
a:hover {
  text-decoration: none !important;
}

.header_main {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.admin-setting .note {
  font-size: 10px;
  padding-left: 14px;
  opacity: 0.8;
  color: red;

}


.footer-section {
  color: white;
  background-color: #C22731 !important;
}

@media screen and (max-width:767px) {
  .serviceCard {
    min-height: auto !important;
    margin-bottom: 0 !important;
  }

  .membershipCard {
    margin-bottom: 0 !important;
  }

  .logo-img {
    width: auto !important;
  }

  .navbar {
    height: auto;
  }

  ul.navbar-nav {
    display: block;
    width: 100%;
    text-align: center;
  }

  li.nav-item {
    display: block;
    text-align: center;
  }
}

.App {
  overflow: hidden !important;
}

.form {
  width: 37%;
  margin: 49px auto;
  box-shadow: 0 0 17px 0 #230c0c38;
  padding: 25px;
  gap: 10px;
  display: flex;
  flex-direction: column;

}


button {
  width: 25%;
  border-radius: 5%;
}

input {
  display: block;
  margin: 0, auto;
}

.form input {
  width: 90%;
  border: 1px solid black;
}

h1 {
  text-align: center;
}

.form_header_text {
  font-size: 20px;
  text-align: center;
}

.form_input {
  width: 60%;
  margin: 49px auto;
  box-shadow: 0 0 17px 0 #230c0c38;
  border-radius: 5px;
  padding: 25px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.form_input {
  border-radius: 15px;
  background: #fff;
}

.tableContainer {
  margin-top: 5%;
}

.exportBtn {
  display: flex;

  margin-top: 2%;
  justify-content: end;
  gap: 20px;
  display: flex;
  margin-top: 2%;
  /* justify-content: space-between; */
  justify-content: end;
  gap: 20px;
  width: auto;
}

.form-section {
  border: 1px solid #b9b8b8;
  padding: 1.5%;
  border-radius: 5px;
}



a.btn.btn-primary.btn-pill:hover {
  background-color: darkmagenta;
}

.enterprise-box {
  background: #fff;
  box-shadow: 0px -1px 20px #e5e5e5;
  border-radius: 20px;
  padding: 40px;
  position: relative;
  top: -80px;
}

.match-img {
  max-width: 100%;
  height: auto;
  width: 80px;
}

.servicesImages {
  padding: 2em 0px;
}

.serviceCard h4 {
  font-weight: bold;
  font-size: 22px;
}

.box_text {
  font-size: 22px;
  text-align: center;
}

.resource-box h1 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: var(--grey-70);
}

.resource_text {
  text-align: center;
}

h4 {
  font-size: 22px;
  font-weight: bold;
}

.requestQuote {

  background: linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.7)), fixed center center;
  background-size: cover;
  padding: 80px 0;
}

.quote_text {
  text-decoration: underline;
}

.btnDiv button {
  width: 100% !important;
}

.adminAddFrom {
  display: flex;
  flex-direction: column;
  gap: 7px;
}




div#basic-navbar-nav {
  background: #f8f9fa;
  z-index: 999999999;
}


.header-menu .navbar-nav .nav-item {
  padding: 10px 26px;
  font-size: 20px;
  text-align: center;

}

.billing-text {
  margin-top: 2%;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {

    justify-content: end;
  }
}

img.logo-img {
  width: 30%;
}

.nav-item>a {
  color: black !important;
  text-decoration: none;
}


@media screen and (max-width:572px) {
  .form_input {
    width: 90%;
  }
}

.feedback>label {
  padding: 4px;
}

.form-section>p {
  font-weight: bold;
}

#contact-form {
  box-shadow: 0 0 17px 0 #230c0c38;
  padding: 25px;
  border-radius: 10px;

}

.form_heading>p {
  font-weight: bold;
}

.detail_section>a {
  color: red;
}

a.btn.btn-secondary.btn-pill:hover {

  background-color: red;
}

.mainDiv {
  overflow-x: scroll;
}

input.btn.btn-pill.btn-outline-primary.mt-3:hover {
  background-color: red;
}

button.btn.btn-dark:hover {
  background-color: #c22731;
}

button.btn.btn-dark {
  margin: 13px;
}

.nav-item>a:hover {
  color: blue !important;
  text-decoration: underline;
}


.contact-info__wrapper {
  overflow: hidden;
  border-radius: .625rem .625rem 0 0;

}

@media (min-width: 1024px) {
  .contact-info__wrapper {
    border-radius: 0 .625rem .625rem 0;
    padding: 5rem !important
  }
}

.contact-info__list span.position-absolute {
  left: 0
}

.z-index-101 {
  z-index: 101;
}

.list-style--none {
  list-style: none;
}

.contact__wrapper {
  background-color: #fff;
  border-radius: 0 0 .625rem .625rem
}

@media (min-width: 1024px) {
  .contact__wrapper {
    border-radius: .625rem 0 .625rem .625rem
  }
}

@media (min-width: 1024px) {
  .contact-form__wrapper {
    padding: 5rem !important
  }
}

.shadow-lg,
.shadow-lg--on-hover:hover {
  box-shadow: 0 1rem 3rem rgba(132, 138, 163, 0.1) !important;
}

.contact__wrapper .contact-info__wrapper {
  background-color: #c4312e;
  color: #fff;
}

.col-md-6.contact-form__wrapper.p-5.order-lg-1>p {
  font-weight: bold;
}

.col-md-8.offset-md-2 {

  padding: 2rem;
  font-weight: bold;
  font-size: 17px;
}

.contact__wrapper {
  margin-bottom: 69px;
}

button.btn.btn-primary.submit_btn {
  background-color: black;
  margin-top: 1em;
}

button.btn.btn-primary.submit_btn:hover {
  background-color: red;
}

.contact-txt {
  font-size: 20px;
  line-height: 34px;
}


/* ===== LOGIN PAGE ===== */
.login-box {
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

@media (min-width: 992px) {
  .login-box {
    margin: 40px 0;
  }
}

.login-box .form-wrap {
  padding: 30px 25px;
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .login-box .form-wrap {
    padding: 45px;
  }
}

@media (min-width: 992px) {
  .login-box .form-wrap {
    margin-top: -40px;
    margin-bottom: -40px;
    padding: 60px;
  }
}

.login-box .socials a {
  -webkit-box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.12);
}

.login-section {
  position: relative;
  z-index: 0;
}

.login-section::after {
  position: absolute;
  content: '';
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.15;
  z-index: -1;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

.login-section::before {
  position: absolute;
  content: '';
  opacity: 0.10;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  background-size: cover;
  background-position: top right;
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

.login-section .content {
  padding: 45px;
}

.form-group .zmdi {
  position: absolute;
  z-index: 1;
  color: #fff;
  background-color: #4e63d7;
  border-radius: 5px;
  height: 100%;
  width: 45px;
  text-align: center;
  font-size: 20px;
  padding-top: 13px;
}



.form-control {
  border: 1px solid #e1e1e1;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 5px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  background-color: #fff;
  color: #858585;
  font-weight: 400;
  position: relative;
}






.login-box .socials a {
  -webkit-box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.12);
}

.socials a {
  width: 35px;
  height: 35px;
  background-color: #6893e1;
  border-radius: 50%;
  -webkit-box-shadow: 0 3px 2px 0 #516cd9;
  box-shadow: 0 3px 2px 0 #516cd9;
  text-align: center;
  color: #fff;
  padding-top: 10px;
  font-size: 16px;
  margin-right: 10px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.form-admin {
  width: 100%;
}

.admin-section {
  background-image: (linear, left top, right top, from(#4e63d7), to(#76bfe9)) !important;
  background-image: linear-gradient(to right, #4e63d7 0%, #76bfe9 100%) !important;
  padding: 12em;
}

@media (min-width: 767px) {
  .admin-section {

    height: 100vh;

  }
}

.header-menu.bg-light {
  padding: 8px 0px 12px 0px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {

  .serviceCard {
    min-height: 365px;
  }



}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {

  .requestQuoteLink .btn.btn-primary.btn-lg.request-btn {
    width: 75%;
  }

}

.admin-container {
  background-image: linear-gradient(to right, #4e63d7 0%, #76bfe9 100%) !important;
  /* min-height: 100vh; */
}

@media only screen and (min-width: 820px) and (max-width: 1180px) {
  .serviceCard {
    min-height: 360px !important;
  }

  p.muted_text {
    font-size: 13px;
  }
}

@media (min-width:851px) {
  a.route {
    font-size: 17px;
  }

}

@media (min-width:844px) {
  a.route {

    font-size: 17px;
  }
}

@media (max-width:280px) {
  .muted_text {

    font-size: 10px;
  }
}

@media (max-width:390px) {
  .muted_text {

    font-size: 10px !important;
  }

}

.profileSection {
  display: flex;
  justify-content: space-between;
}

.Billing_Section {
  margin-top: 10px;
}

@media (max-width:600px) {

  .main-page {
    width: fit-content !important;
  }
}

h3.billing_txt {

  padding-top: 20px;
}

button.btn.btn-info.update_btn {
  margin-top: 27px;
  width: auto;
}

span.require {
  color: red;
}


a.list-group-item {
  font-size: 20px;
  padding: 1em;
}

a.list-group-item:hover {
  color: black;
}

a.list-group-item>i:hover {
  visibility: visible;
}

a .list-group-item>i {
  visibility: hidden;
}

button.ant-btn.css-dev-only-do-not-override-1jyprxx.ant-btn-primary {
  margin-top: 32px;
}

/* .form-control{
  
  margin-bottom: 4em;
 
} */
a.btn.btn-secondary.btn-pill {
  background-color: black;
}

.dashBoardLogo {
  width: 50% !important;
}

@media (min-width:390px) {
  .reset_password {
    width: 54%;
  }

}

img.dashBoard_img {
  width: 200px;
}

.taxation .accordion-body {
  padding: 25px !important;
}

.taxation .accordion-button {
  font-size: 20px;
  text-transform: uppercase;
}

.taxation .list-group-item {
  padding: 20px 12px;
  background: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  margin-bottom: 10px;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.taxation .list-group-item:hover {
  transform: scaleX(1.1);
}

.logoLink {
  width: 30%;
}
.ant-select.ant-select-lg.css-1jyprxx.ant-select-single.ant-select-show-arrow {
  width: 100% !important;
}

.ant-table-wrapper.css-1jyprxx {
  overflow-x: scroll;
}
.ant-select.ant-select-lg.css-1jyprxx.ant-select-single.ant-select-show-arrow {
  width: 100% !important;
}

.ant-table-wrapper.css-1jyprxx {
  overflow-x: scroll;
}
.request-progress{
  max-height: 500px;
  overflow-y: scroll;
}

.request-progress p{
  margin-top: 10px;
}
.request-progress b{
  margin-right: 10px;
}
@media (max-width:390px){
  button.ant-btn.css-dev-only-do-not-override-1jyprxx.ant-btn-default {
    width: 32%;
    font-size: 8px;
}

}
@media (max-width:412px){
  button.ant-btn.css-dev-only-do-not-override-1jyprxx.ant-btn-default {
    font-size: 8px;
}
select#exampleFormControlSelect2 {
  margin-bottom: 13px;
}
}



@media (max-width:540px){
  select#exampleFormControlSelect2 {
    margin-bottom: 13px;
  }
}





@media(max-width:414px){
  button.ant-btn.css-dev-only-do-not-override-1jyprxx.ant-btn-default {
    font-size: 8px;
}
}
@media (max-width:390px){

button.ant-btn.css-1jyprxx.ant-btn-default {
  width: 32%;
  font-size: 8px;
}
}
@media (max-width:412px){
  button.ant-btn.css-1jyprxx.ant-btn-default {
 
    font-size: 8px;
  }
}
@media (max-width:414px){
   button.ant-btn.css-1jyprxx.ant-btn-default {
 
    font-size: 8px;
  }

}