body {
  font-family: "Lato", sans-serif;
}
.blur {
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
}
.nocsroll {
  overflow-y: hidden;
}
.navHeaderWrap {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
  background: transparent;
  height: 61.6px;
}
/******/
.navbar {
  position: relative;
  height: auto; /***What i tweak from reactstrap*/
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  width: 170px; /***What i tweak from reactstrap*/
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
/******/
.bodyWrap {
  /* margin-top: 63px; */
  position: relative;
  z-index: 100;
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 62px; /*This give space for navbar at top*/
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 15px;
  box-sizing: border-box;
}
.main {
  transition: margin-left 0.5s;
  padding: 16px;
  background: #fff;
  box-sizing: border-box;
  height: 100%;
  display: block;
}
/*******************/

.sidenavOpen {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 62px; /*This give space for navbar at top*/
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 15px;
  width: 15%;
}
.btnDiv {
  display: flex;
  gap: 7%;
}

.mainShrink {
  transition: margin-left 0.5s;
  padding: 16px;
  background: #fff;
  overflow-y: scroll;
  height: 100vh;
  display: block;
  padding-top: 5%;
  margin-left: 15%;
}

/*******************/
.sidenavClose {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 62px; /*This give space for navbar at top*/
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 15px;
  width: 0px;
}

.mainExpand {
  transition: margin-left 0.5s;
  padding: 16px;
  background: #fff;
  height: 100vh;
  padding-top: 1%;
  overflow: scroll;
  display: block;
}
/*******************/
.sidenav a {
  padding: 6% 0 0 0;
  text-decoration: none;
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}
.hidex {
  display: none !important;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* @media screen and (min-width: 1024px) {
  .sidenav {
    width: 250px;
  }
  .sidenav a {
    font-size: 18px;
  }
  .main {
    margin-left: 250px;
  }
} */

/* @media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
} */

@media screen and (max-width: 425px) {
  .bodyWrap {
    margin-top: 53px;
    position: relative;
    z-index: 100;
  }
  .sidenavOpen {
    top: 52px;
  }
}
@media screen and (max-width: 766px) {
  .navbar {
    position: relative;
    height: auto;
  }
}
@media screen and (max-width: 768px) {
  .sidenav {
    width: 150px;
  }
  .main {
    margin-left: 150px;
  }

  .hidex {
    display: block !important;
  }
  .sidenavOpen {
    width: 250px;
  }
  .mainShrink {
    margin-left: 0px;
  }

  .sidenavClose {
    width: 0px;
  }

  .mainExpand {
    margin-left: -10px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
