
th.ant-table-cell {
    background-color: aquamarine;
  }

a{
    color: red; 
}

.is-active{
    border: 1px solid black;
}
.header {
    margin: 1% 0%;
    box-shadow: 0px 13px 7px -15px #111;

}

.header .Links{
    column-gap: 5%;
    margin: 5% 0;

}
.services{
    margin-bottom: 10%;
    line-height: 3rem;
    text-align: center;
}
.header .Links span
{
    font-weight: 600;
    font-size: 18px;

}
.servicesImages img{
    width:80px
}
.serviceCard {
    border: 1px solid #dfdcdc;
    padding: 4% 4%;
    border-radius: 3%;
    text-align: center;
}
.membershipCard{
    border: 1px solid #dfdcdc;
    padding: 4% 4%;
    border-radius: 10px;
    text-align: center;
  }
.solutionSection{
    text-align: center;
}
.ChooseUsSection {
}
.requestQuote{
    background: linear-gradient(105deg,#6e99e6 ,#C22731);
color: #fff;
text-align: center;
}
.requestQuoteLink{
    padding: 2%;
}
p.text-muted.aboutSection1 {
    line-height: 2.1;
    margin-top: 14%;
}
li.NavLinkHeading {
    color: white;
    font-size: 1rem;
    margin-bottom: 13%;
}
ul { list-style-type: none; }
.hmbger {
    color: #111;
    margin-left: 24%;
    cursor: pointer;
}
  
  @media screen and (max-width: 768px) {
    .hmbger {
      color: #111;
      float: left;
      margin-right: 10px;
    }
  }
  


@media only screen and (max-width: 991px)and (min-width: 10px)   {
body{
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 4rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}
    .header{
        text-align: center;
    }
    .header .Links span
    {
        padding-top: 2%;
      
    }


}
@media only screen and (max-width: 991px)and (min-width: 10px)   {
    
}
.mainFooter{
    padding-top: 1%;
    justify-content: center;
}

.Links a{
    text-decoration: none;
    color: #111;
}
.Links a:active{
    color: #C22731;
}


.static section {
    padding: 4em 0px;
}

button.accordion-button.collapsed {
   
}

.progress{
    height:9px;
}
.text-muted{
font-size: 18px;
}
.muted_text{
    word-break: keep-all;
}
.serviceCard{
    min-height: 325px;;
}
#root{
    overflow: hidden;
}

.image-business{
    width:100%;
}
.btn.btn-primary.btn-lg.request-btn {
    border: 1px solid #fff;
    background: #fff;
    width: 65%;
    padding: 12px;
  }


  .selectediv {
    border: 2px solid #bb1414 !important;
}
.subCategoryShow {
    display: flex;
    gap: 1%;
}
span.subCategoryArray {
    display: flex;
    gap: 2%;
    border: 1px solid black;
    justify-content: center;
    padding: 0.5%;
    border-radius: 6px;
}
.removeSubCategory{
    cursor: pointer;
}
form.userRequestForm {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.MainDiv {
    overflow-x: scroll !important;
}
/* .dashBoardLogo{
    
    width: 17%;
} */
.adminHeader {
    display: flex;
    justify-content: space-between;
}
span.logoutBtn {
    padding-right: 3%;
    cursor: pointer;
}



@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

  .serviceCard {
    min-height: 365px;
}



}
/* .btn.btn-primary.btn-lg.request-btn:hover{
 
    color: #111  !important;
    background-color:#fff !important;
  } */
  .admin-container #email {
    padding-left: 48px;
}
.admin-container #password{
    padding-left:48px;
}